import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/opt/competition-platform-admin/node_modules/_core-js@2.6.12@core-js/modules/es6.array.iterator.js";
import "/opt/competition-platform-admin/node_modules/_core-js@2.6.12@core-js/modules/es6.promise.js";
import "/opt/competition-platform-admin/node_modules/_core-js@2.6.12@core-js/modules/es6.object.assign.js";
import "/opt/competition-platform-admin/node_modules/_core-js@2.6.12@core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss'; // global css

import App from "./App";
import router from "./router";
import store from "./store";
import '@/icons'; // icon
import '@/permission'; // permission control

import * as filters from "./utils/filters/filters";
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}
Vue.use(ElementUI);
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
// use

Vue.use(mavonEditor);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});