import "core-js/modules/es6.regexp.replace";
import axios from "axios";
import { MessageBox, Notification, Loading } from "element-ui";
import qs from "qs";
import store from "@/store";
import router from "@/router";
import { getToken } from "@/utils/auth";

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000,
  // request timeout
  //跨域请求时是否需要使用凭证
  // withCredentials: true,
  //参数序列化
  paramsSerializer: function paramsSerializer(params) {
    return qs.stringify(params, {
      indices: false
    });
  }
});
service.interceptors.request.use(function (config) {
  config.headers["x-site-code"] = process.env.VUE_APP_SITE_ID;
  config.headers["x-channel-code"] = process.env.VUE_APP_CHANNEL_ID;
  config.headers["Content-Type"] = "application/json;charset=UTF-8";
  if (store.getters.token) {
    config.headers["Authorization"] = store.getters.token;
  }
  if (config.needLoading) {
    startLoading();
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  if (response.config.needLoading) {
    stopLoading();
  }
  if (response.status === 401) {
    store.dispatch("user/resetToken").then(function () {
      location.reload();
    });
  }
  if (response.status !== 200) {
    return Promise.reject(response);
  }
  var res = response.data;
  // 是否显示错误信息提示，默认 true
  var showErrorTip = response.config.showErrorTip !== undefined ? response.config.showErrorTip : true;
  // 是否显示正确信息提示，默认 false
  var showSuccessTip = response.config.showSuccessTip !== undefined ? response.config.showSuccessTip : false;
  if (!res.ok) {
    // 需要显示错误信息提示时
    if (showErrorTip) {
      Notification.closeAll();
      Notification({
        title: "错误",
        dangerouslyUseHTMLString: true,
        message: "错误码：" + res.status + "<br/>错误信息：" + res.message,
        type: "error",
        duration: 3000
      });
    }
    return Promise.reject(response);
  }
  // 需要显示成功信息提示时
  if (showSuccessTip) {
    Notification.closeAll();
    Notification({
      title: "成功",
      message: "操作成功",
      type: "success",
      duration: 1000
    });
  }
  if (response.headers && response.headers.authorization) {
    store.dispatch("user/setUserToken", response.headers.authorization);
  }
  return Promise.resolve(res);
}, function (error) {
  if (error.response && error.response.status === 401) {
    // store.dispatch('user/resetToken').then(() => {
    //     location.reload()
    // })
    MessageBox.confirm("登录信息已过期，请重新登录", "确认登出", {
      confirmButtonText: "重新登录",
      showClose: false,
      showCancelButton: false,
      type: "warning"
    }).then(function () {
      var currentPath = router.currentRoute.path;
      if (!currentPath || currentPath === "/" || currentPath === "/login") {
        router.replace("/login");
      } else {
        router.replace({
          path: "/login",
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
      }
      // store.dispatch('user/resetToken').then(() => {
      //     location.reload()
      // })
    });
    return;
  }
  if (error.config.needLoading) {
    stopLoading();
  }
  return Promise.reject(error);
});
var loading;
// 有loading的请求个数
var loadingRequestCount = 0;
// 延迟300ms显示loading，防止响应速度过快出现闪屏现象
var loadingTime;

//显示Loading
var startLoading = function startLoading() {
  if (loadingRequestCount === 0) {
    loadingTime = setTimeout(function () {
      loading = Loading.service({
        lock: true,
        text: "加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }, 500);
  }
  loadingRequestCount++;
};

//隐藏Loading
var stopLoading = function stopLoading() {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingTime) {
    clearTimeout(loadingTime);
    loadingTime = null;
  }
  if (loadingRequestCount === 0 && loading) {
    loading.close();
  }
};
export default service;