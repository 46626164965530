import "core-js/modules/es6.function.name";
import _defineProperty from "/opt/competition-platform-admin/node_modules/_@babel_runtime-corejs2@7.23.7@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import Vue from "vue";
import Router from "vue-router";
/* Layout */
import Layout from "@/layout";
import { checkPower } from "@/utils/auth";
Vue.use(Router);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

var constantRoutes = [{
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return import("@/views/404");
  },
  hidden: true
}, {
  path: "/",
  component: Layout,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    name: "Dashboard",
    component: function component() {
      return import("@/views/dashboard/index");
    },
    meta: {
      title: "首页",
      icon: "icon-home"
    }
  }]
},
// 竞赛管理
{
  path: "/competition",
  component: Layout,
  redirect: "/competition/manage",
  name: "competition",
  meta: {
    title: "竞赛管理",
    icon: "icon-competition2"
  },
  children: [{
    path: "manage",
    name: "Manage",
    component: function component() {
      return import("@/views/competition/index");
    },
    meta: {
      title: "数据竞赛",
      icon: "icon-competition2"
    }
  }, {
    path: "modelComp",
    name: "modelComp",
    component: function component() {
      return import("@/views/modelComp/index");
    },
    meta: {
      title: "建模竞赛",
      icon: "icon-competition2"
    }
  }, {
    path: "dataStatistics",
    name: "dataStatistics",
    component: function component() {
      return import("@/views/dataStatistics/index");
    },
    meta: {
      title: "数据统计",
      icon: "icon-competition2"
    }
  }, {
    path: "tag",
    name: "competition-tag-manage",
    component: function component() {
      return import("@/views/tag-manage/index");
    },
    meta: {
      title: "标签管理",
      icon: "icon-tag"
    }
  }, {
    path: "banner",
    name: "banner-manage",
    component: function component() {
      return import("@/views/banner-manage/index");
    },
    meta: {
      title: "宣传管理",
      icon: "icon-tag"
    }
  }, {
    path: "apply",
    name: "apply-manage",
    component: function component() {
      return import("@/views/apply-manage/index");
    },
    meta: {
      title: "审核管理",
      icon: "icon-tag"
    }
  },
  // {
  //     path: 'logs',
  //     name: 'Logs',
  //     hidden:true,
  //     component: () => import('@/views/logs/index'),
  //     meta: {title: '日志管理', icon: 'iconxueshengjingsaitianbao'}
  // },
  {
    path: "joinInUser",
    name: "joinInUser",
    component: function component() {
      return import("@/views/joinInUser/index");
    },
    meta: {
      title: "数据参赛列表",
      icon: "icon-tag"
    }
  }, {
    path: "modelContest",
    name: "modelContest",
    component: function component() {
      return import("@/views/modelContest/index");
    },
    meta: {
      title: "建模参赛列表",
      icon: "icon-tag"
    }
  }, {
    path: "worksList",
    name: "workLsist",
    component: function component() {
      return import("@/views/worksList/index");
    },
    meta: {
      title: "作品列表",
      icon: "icon-tag"
    }
  }, {
    path: "taskList",
    name: "taskList",
    component: function component() {
      return import("@/views/taskList/index");
    },
    meta: {
      title: "任务列表",
      icon: "icon-tag"
    }
  }]
},
// 数据集管理
{
  path: "/dataset",
  component: Layout,
  redirect: "/dataset/index",
  meta: {
    title: "数据集管理",
    icon: "iconshimingrenzheng1"
  },
  children: [{
    path: "/dataset/index",
    name: "dataset",
    component: function component() {
      return import("@/views/dataset");
    },
    meta: {
      title: "官方数据集列表",
      icon: "iconshimingrenzheng1"
    }
  }, {
    path: "/userDataset/index",
    name: "userDataset",
    component: function component() {
      return import("@/views/userDataset");
    },
    meta: {
      title: "用户数据集列表",
      icon: "iconshimingrenzheng1"
    }
  }, {
    path: "/tag-manage",
    name: "dataset-tag-manage",
    component: function component() {
      return import("@/views/dataset/tag-manage");
    },
    meta: {
      title: "标签管理",
      icon: "iconshimingrenzheng1"
    }
  }]
},
// 模板管理
{
  path: "/template",
  component: Layout,
  // redirect: '/message',
  // name: 'message',
  // meta: {title: '消息管理', icon: 'iconxinxiguanli'},
  children: [{
    path: "/template",
    name: "template",
    component: function component() {
      return import("@/views/message/template/MessageTemplate");
    },
    meta: {
      title: "模板管理",
      icon: "iconxiaoximoban"
    }
  }
  // {
  //     path: 'notify',
  //     component: () => import('@/views/message/notice/MessageNotify'),
  //     name: 'notify',
  //     meta: {title: '消息通知', icon: 'icontongzhi'}
  // }
  ]
},
//  2022-12-22 新增用户群组列表
{
  path: "/usermanage",
  name: "usermanage",
  redirect: "/usermanage/list",
  component: Layout,
  meta: {
    title: "用户管理",
    icon: "iconshimingrenzheng1"
  },
  children: [{
    path: "/usermanage/list",
    name: "userlist",
    component: function component() {
      return import("@/views/usermanage");
    },
    meta: {
      title: "用户列表",
      icon: "iconshimingrenzheng1"
    }
  }, {
    path: "/usermanage/group",
    name: "group",
    component: function component() {
      return import("@/views/usermanage/group");
    },
    meta: {
      title: "用户群组",
      icon: "iconshimingrenzheng1"
    }
  }]
},
// 社区管理
{
  path: "/community",
  component: Layout,
  redirect: '/community/post-manage',
  name: "community",
  meta: {
    title: "社区管理",
    icon: "icon-community"
  },
  children: [{
    path: "post-review",
    name: "PostReview",
    component: function component() {
      return import("@/views/community/PostReview");
    },
    meta: {
      title: "帖子审核",
      icon: "icon-review"
    }
  }, {
    path: "post-manage",
    name: "PostManage",
    component: function component() {
      return import("@/views/community/PostManage");
    },
    meta: {
      title: "帖子管理",
      icon: "icontieziguanli"
    }
  }, {
    path: "tag-manage",
    name: "TagManage",
    component: function component() {
      return import("@/views/community/TagManage");
    },
    meta: {
      title: "标签管理",
      icon: "icon-tag"
    }
  }, {
    path: "ogc",
    name: "Ogc",
    component: function component() {
      return import("@/views/community/OGCUpload");
    },
    meta: {
      title: "OGC上传",
      icon: "icon-tag"
    }
  }, {
    path: "integral-manage",
    name: "IntegralManage",
    component: function component() {
      return import("@/views/community/IntegralManage");
    },
    meta: {
      title: "社区配置",
      icon: "icon-tag"
    }
  }]
},
// 系统配置
{
  path: "/adminconf",
  name: "adminconf",
  component: Layout,
  redirect: '/adminconf/userconf',
  meta: {
    title: "系统配置",
    icon: "iconshimingrenzheng1"
  },
  children: [{
    path: "/adminconf/userconf",
    name: "userconf",
    component: function component() {
      return import("@/views/adminconf/userconf");
    },
    meta: {
      title: "人员管理",
      icon: "iconshimingrenzheng1"
    }
  }, {
    path: "/adminconf/roleconf",
    name: "roleconf",
    component: function component() {
      return import("@/views/adminconf/roleconf");
    },
    meta: {
      title: "角色配置",
      icon: "iconshimingrenzheng1"
    }
  }]
},
// 兑换码管理  注意顺序
{
  path: "/exchangeCode",
  name: "exchangeCode",
  redirect: '/exchangeCode/list',
  component: Layout,
  meta: {
    title: "兑换码管理",
    icon: "iconshimingrenzheng1"
  },
  children: [{
    path: "/exchangeCode/list",
    name: "exchangeCodelist",
    component: function component() {
      return import("@/views/exchangeCode");
    },
    meta: {
      title: "兑换码记录",
      icon: "iconshimingrenzheng1"
    }
  }]
},
// 计算资源管理
{
  path: "/computingResource",
  name: "computingResource",
  redirect: '/computingResource/set',
  component: Layout,
  meta: {
    title: "计算资源管理",
    icon: "iconshimingrenzheng1"
  },
  children: [
  // {
  //   path: "/computingResource/list",
  //   name: "computingResourceList",
  //   component: () => import("@/views/computingResource"),
  //   meta: {title: "集群列表", icon: "iconshimingrenzheng1"}
  // },
  {
    path: "/computingResource/set",
    name: "computingResourceSet",
    component: function component() {
      return import("@/views/computingResource/setting");
    },
    meta: {
      title: "平台资源配置",
      icon: "iconshimingrenzheng1"
    }
  }]
},
// 20230607 项目管理-公共项目
{
  path: "/project",
  name: "project",
  component: Layout,
  redirect: '/projectmanage/userProjectList',
  meta: {
    title: "项目管理",
    icon: "iconsaishixiangqing"
  },
  children: [{
    path: "/projectmanage/public",
    name: "projectPublic",
    component: function component() {
      return import("@/views/project/public");
    },
    meta: {
      title: "公共项目",
      icon: "iconxinxiguanli"
    }
  }, {
    path: "/projectmanage/userProjectList",
    name: "userProjectList",
    component: function component() {
      return import("@/views/project/userProjectList");
    },
    meta: {
      title: "用户项目列表",
      icon: "iconxinxiguanli"
    }
  }]
},
// 活动管理
{
  path: "/activity",
  component: Layout,
  name: "activity",
  redirect: '/activity/list',
  meta: {
    title: "活动管理",
    icon: "iconxinxiguanli"
  },
  children: [{
    path: "/activity/list",
    name: "activityList",
    component: function component() {
      return import("@/views/activity/index");
    },
    meta: {
      title: "活动管理",
      icon: "iconxiaoximoban"
    }
  }, {
    path: "/activePersonnel/list",
    name: "activePersonnelList",
    component: function component() {
      return import("@/views/activePersonnel/index");
    },
    meta: {
      title: "活动人员管理",
      icon: "iconxiaoximoban"
    }
  }]
}, {
  path: "/previewActivity",
  component: function component() {
    return import("@/views/activity/preview");
  },
  hidden: true
},
// 资讯动态
{
  path: "/news",
  component: Layout,
  name: "news",
  redirect: '/news/list',
  meta: {
    title: "资讯动态",
    icon: "iconxinxiguanli"
  },
  children: [{
    path: "/news/list",
    name: "newsList",
    component: function component() {
      return import("@/views/news/index");
    },
    meta: {
      title: "资讯动态",
      icon: "iconxiaoximoban"
    }
  }]
},
// AI工具管理
{
  path: "/aiTools",
  component: Layout,
  name: "aiTools",
  redirect: '/aiTools/list',
  meta: {
    title: "AI工具管理",
    icon: "iconxinxiguanli"
  },
  children: [{
    path: "/aiTools/list",
    name: "aiToolsList",
    component: function component() {
      return import("@/views/aiTools/index");
    },
    meta: {
      title: "AI工具管理",
      icon: "iconxiaoximoban"
    }
  }]
},
// 内容管理
{
  path: "/content",
  name: "content",
  component: Layout,
  redirect: '/content/recommend',
  meta: {
    title: "内容管理",
    icon: "iconxinxiguanli"
  },
  children: [{
    path: "/content/recommend",
    name: "contentRecommend",
    component: function component() {
      return import("@/views/contentManage/recommendConfig/index");
    },
    meta: {
      title: "推荐配置",
      icon: "iconxiaoximoban"
    }
  }]
}, {
  path: "/previewNews",
  component: function component() {
    return import("@/views/news/preview");
  },
  hidden: true
},
// 课程管理
{
  path: "/courseManage",
  component: Layout,
  name: "courseManage",
  redirect: '/courseManage/list',
  meta: {
    title: "课程管理",
    icon: "iconxinxiguanli"
  },
  children: [{
    path: "/courseManage/list",
    name: "courseManageList",
    component: function component() {
      return import("@/views/courseManage/index");
    },
    meta: {
      title: "课程管理",
      icon: "iconxiaoximoban"
    }
  }]
}, // 需求管理-数据产品
// {
//   path: "/demandProduct",
//   component: Layout,
//   name: "demandProduct",
//   meta: { title: "需求管理-数据产品", icon: "iconxinxiguanli" },
//   children: [
//     // {
//     //   path: "/demandProduct/list",
//     //   name: "demandProduct",
//     //   component: () => import("@/views/demandProduct/index"),
//     //   meta: { title: "需求列表", icon: "iconxiaoximoban" },
//     // },
//     {
//       path: "/productManage/list",
//       name: "productManage",
//       component: () => import("@/views/productManage/index"),
//       meta: { title: "数据产品管理", icon: "iconxiaoximoban" },
//     }
//   ]
// },
// // 算力开放
// {
//   path: "/demandProduct",
//   component: Layout,
//   name: "demandProduct",
//   meta: { title: "算力开放", icon: "iconxinxiguanli" },
//   children: [ 
//     {
//       path: "/newTrendsManage/list",
//       name: "newTrendsManage",
//       component: () => import("@/views/newTrendsManage/index"),
//       meta: { title: "M币配置减配版", icon: "iconxiaoximoban" },
//     }
//   ]
// },
_defineProperty(_defineProperty({
  path: "/details",
  component: Layout
}, "component", function component() {
  return import("@/views/details");
}), "hidden", true)];
function setRoute() {
  constantRoutes = [{
    path: "/login",
    component: function component() {
      return import("@/views/login/index");
    },
    hidden: true
  }, {
    path: "/404",
    component: function component() {
      return import("@/views/404");
    },
    hidden: true
  }, {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [{
      path: "dashboard",
      name: "Dashboard",
      component: function component() {
        return import("@/views/dashboard/index");
      },
      meta: {
        title: "首页",
        icon: "icon-home"
      }
    }]
  }
  // {
  //     path: '/logs',
  //     component: Layout,
  //     redirect: '/logs/index',
  //     children: [{
  //         path: '/logs',
  //         name: 'logs',
  //         component: () => import('@/views/logs/index'),
  //         meta: {title: '操作日志', icon: 'iconguifanshuoming'}
  //     }]
  // },
  // {
  //     path: '/feedBack',
  //     component: Layout,
  //     redirect: '/feedBack/index',
  //     children: [{
  //         path: '/feedBack',
  //         name: 'feedBack',
  //         component: () => import('@/views/feedBack/index'),
  //         meta: {title: '意见反馈', icon: 'icontongzhi'}
  //     }]
  // },
  ];

  // 竞赛模块
  var compModule = {
    path: "/competition",
    component: Layout,
    // redirect: '/competition/manage',
    name: "competition",
    meta: {
      title: "竞赛管理",
      icon: "icon-competition2"
    },
    children: [
      // {
      //     path: 'logs',
      //     name: 'Logs',
      //     hidden:true,
      //     component: () => import('@/views/logs/index'),
      //     meta: {title: '日志管理', icon: 'iconxueshengjingsaitianbao'}
      // },
    ]
  };
  if (checkPower("/competition/manage") && checkPower("/competition/manage/list")) {
    compModule.children.push({
      path: "manage",
      name: "Manage",
      component: function component() {
        return import("@/views/competition/index");
      },
      meta: {
        title: "竞赛管理",
        icon: "icon-competition2"
      }
    });
  }
  if (checkPower("/competition/tag") && checkPower("/competition/tag/list")) {
    compModule.children.push({
      path: "tag",
      name: "tag-manage",
      component: function component() {
        return import("@/views/tag-manage/index");
      },
      meta: {
        title: "标签管理",
        icon: "icon-tag"
      }
    });
  }
  if (compModule.children.length) {
    compModule.redirect = "/competition/" + compModule.children[0].name;
    constantRoutes.push(compModule);
  }

  // 模板管理模块
  if (checkPower("/template") && checkPower("/template/list")) {
    var templateModule = {
      path: "/template",
      component: Layout,
      // redirect: '/message',
      // name: 'message',
      // meta: {title: '消息管理', icon: 'iconxinxiguanli'},
      children: [{
        path: "/template",
        name: "template",
        component: function component() {
          return import("@/views/message/template/MessageTemplate");
        },
        meta: {
          title: "模板管理",
          icon: "iconxiaoximoban"
        }
      }
      // {
      //     path: 'notify',
      //     component: () => import('@/views/message/notice/MessageNotify'),
      //     name: 'notify',
      //     meta: {title: '消息通知', icon: 'icontongzhi'}
      // }
      ]
    };
    constantRoutes.push(templateModule);
  }

  // 用户管理模块
  if (checkPower("/usermanage") && checkPower("/usermanage/list")) {
    var userModule = {
      path: "/usermanage",
      component: Layout,
      children: [{
        path: "/usermanage",
        name: "UserManage",
        component: function component() {
          return import("@/views/usermanage");
        },
        meta: {
          title: "用户管理",
          icon: "iconshimingrenzheng1"
        }
      }]
    };
    constantRoutes.push(userModule);
  }

  // 社区模块
  var communityMudule = {
    path: "/community",
    component: Layout,
    // redirect: '/community/post-review',
    name: "community",
    meta: {
      title: "社区管理",
      icon: "icon-community"
    },
    children: []
  };
  if (checkPower("/community/post-review") && checkPower("/community/post-review/list")) {
    communityMudule.children.push({
      path: "post-review",
      name: "PostReview",
      component: function component() {
        return import("@/views/community/PostReview");
      },
      meta: {
        title: "帖子审核",
        icon: "icon-review"
      }
    });
  }
  if (checkPower("/community/post-manage") && checkPower("/community/post-manage/list")) {
    communityMudule.children.push({
      path: "post-manage",
      name: "PostManage",
      component: function component() {
        return import("@/views/community/PostManage");
      },
      meta: {
        title: "帖子管理",
        icon: "icontieziguanli"
      }
    });
  }
  if (checkPower("/community/tag-manage") && checkPower("/community/tag-manage/list")) {
    communityMudule.children.push({
      path: "tag-manage",
      name: "TagManage",
      component: function component() {
        return import("@/views/community/TagManage");
      },
      meta: {
        title: "标签管理",
        icon: "icon-tag"
      }
    });
  }
  if (checkPower("/community/ogc") && checkPower("/community/ogc/list")) {
    communityMudule.children.push({
      path: "ogc",
      name: "Ogc",
      component: function component() {
        return import("@/views/community/OGCUpload");
      },
      meta: {
        title: "OGC上传",
        icon: "icon-tag"
      }
    });
  }
  if (checkPower("/community/integral-manage") && checkPower("/community/integral-manage/list")) {
    communityMudule.children.push({
      path: "integral-manage",
      name: "IntegralManage",
      component: function component() {
        return import("@/views/community/IntegralManage");
      },
      meta: {
        title: "社区配置",
        icon: "icon-tag"
      }
    });
  }
  if (communityMudule.children.length) {
    communityMudule.redirect = "/community/" + communityMudule.children[0].name;
    constantRoutes.push(communityMudule);
  }

  // 系统模块
  var sysModule = {
    path: "/adminconf",
    name: "adminconf",
    component: Layout,
    // redirect: '/adminconf/userconf',
    meta: {
      title: "系统配置",
      icon: "iconshimingrenzheng1"
    },
    children: []
  };
  if (checkPower("/adminconf/userconf") && checkPower("/adminconf/userconf/list")) {
    sysModule.children.push({
      path: "/adminconf/userconf",
      name: "userconf",
      component: function component() {
        return import("@/views/adminconf/userconf");
      },
      meta: {
        title: "人员管理",
        icon: "iconshimingrenzheng1"
      }
    });
  }
  if (checkPower("/adminconf/roleconf") && checkPower("/adminconf/roleconf/list")) {
    sysModule.children.push({
      path: "/adminconf/roleconf",
      name: "roleconf",
      component: function component() {
        return import("@/views/adminconf/roleconf");
      },
      meta: {
        title: "角色配置",
        icon: "iconshimingrenzheng1"
      }
    });
  }
  if (sysModule.children.length) {
    sysModule.redirect = "/adminconf/" + sysModule.children[0].name;
    constantRoutes.push(sysModule);
  }

  // 兑换码模块
  if (checkPower("/exchangeCode") && checkPower("/exchangeCode/list")) {
    var exchangeCodeModule = {
      path: "/exchangeCode",
      component: Layout,
      children: [{
        path: "/exchangeCode",
        name: "ExchangeCode",
        component: function component() {
          return import("@/views/exchangeCode/list");
        },
        meta: {
          title: "兑换码管理",
          icon: "iconshimingrenzheng1"
        }
      }]
    };
    constantRoutes.push(exchangeCodeModule);
  }

  // 活动模块
  if (checkPower("/activity") && checkPower("/activity/list")) {
    var activityModule = {
      path: "/activity",
      component: Layout,
      children: [{
        path: "/activity/list",
        name: "activityList",
        component: function component() {
          return import("@/views/activity/index");
        },
        meta: {
          title: "活动管理",
          icon: "iconxiaoximoban"
        }
      }]
    };
    constantRoutes.push(activityModule);
  }

  // 资讯动态模块
  if (checkPower("/news") && checkPower("/news/list")) {
    var newsModule = {
      path: "/news",
      component: Layout,
      children: [{
        path: "/news/list",
        name: "newsList",
        component: function component() {
          return import("@/views/news/index");
        },
        meta: {
          title: "资讯动态",
          icon: "iconxiaoximoban"
        }
      }]
    };
    constantRoutes.push(newsModule);
  }

  // AI工具管理模块
  if (checkPower("/aiTools") && checkPower("/aiTools/list")) {
    var aiToolsModule = {
      path: "/aiTools",
      component: Layout,
      children: [{
        path: "/aiTools/list",
        name: "aiToolsList",
        component: function component() {
          return import("@/views/aiTools/index");
        },
        meta: {
          title: "AI工具管理",
          icon: "iconxiaoximoban"
        }
      }]
    };
    constantRoutes.push(aiToolsModule);
  }

  // 课程模块
  if (checkPower("/courseManage") && checkPower("/courseManage/list")) {
    var _activityModule = {
      path: "/courseManage",
      component: Layout,
      children: [{
        path: "/courseManage/list",
        name: "courseManageList",
        component: function component() {
          return import("@/views/courseManage/index");
        },
        meta: {
          title: "课程管理",
          icon: "iconxiaoximoban"
        }
      }]
    };
    constantRoutes.push(_activityModule);
  }

  // 404 page must be placed at the end !!!
  constantRoutes.push({
    path: "*",
    redirect: "/404",
    hidden: true
  });
}

// setRoute();

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// setTimeout(() => {
// 	setRoute();
// 	resetRouter();
// }, 500);
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  // setRoute();
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;