var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-header", [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            staticClass: "sidebar-logo",
            attrs: { src: require("@/assets/img/logo.png") },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [_vm._v("后台管理系统")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c(
            "span",
            {
              staticClass: "el-dropdown-link",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c("i", { staticClass: "iconfont icongerenziliao" }),
              _vm._v(_vm._s(_vm.userName) + "\n        "),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "m-l-20",
              staticStyle: { "font-size": "16px", cursor: "pointer" },
              on: { click: _vm.changePwd },
            },
            [_vm._v("修改密码")]
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "iconfont iconguanji",
            attrs: { title: "退出" },
            on: {
              click: function ($event) {
                return _vm.logout()
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showChangePwd,
            title: "修改密码",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showChangePwd = $event
            },
            close: _vm.onDialogClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changePwd",
              attrs: {
                model: _vm.pwdForm,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原密码：", prop: "oldPassword" } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "", placeholder: "请输入原密码" },
                    model: {
                      value: _vm.pwdForm.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.pwdForm, "oldPassword", $$v)
                      },
                      expression: "pwdForm.oldPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码：", prop: "newPassword" } },
                [
                  _c("el-input", {
                    attrs: {
                      "show-password": "",
                      placeholder: "请输入新密码密码",
                    },
                    model: {
                      value: _vm.pwdForm.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.pwdForm, "newPassword", $$v)
                      },
                      expression: "pwdForm.newPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showChangePwd = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }