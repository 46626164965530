// 配色题基准色列
export var BASE_COLORS = ['6fba2c', '009843', '02a09b', '1f6fbc', '3f48a0', '703393', 'c7007c', 'e60011', 'ef6f01', 'f29700', 'fcbd00', 'fdf001'];

// 过滤器对象
export var FILTER_OBJ = {
  enableState: {
    0: '停用',
    1: '启用'
  },
  disableFlag: {
    false: '停用',
    true: '启用'
  },
  realNameAuth: {
    true: '实名认证：已认证',
    false: '实名认证：未认证'
  },
  operateState: {
    succeed: '成功',
    fail: '失败'
  },
  roleType: {
    0: '自定义',
    1: '系统默认'
  },
  userLocked: {
    0: '启用',
    1: '停用'
  },
  dicType: {
    0: '系统默认',
    1: '自定义'
  },
  boolean: {
    true: '是',
    false: '否'
  },
  topicVisibleStatus: {
    true: '公开',
    false: '不显示'
  },
  auditStatus: {
    0: '待审核',
    1: '审核通过',
    2: '审核不通过'
  }
};
export var TIME_LIST = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];