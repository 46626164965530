import { login, logout } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import { getUserRoleDetails } from '@/api/common';
var getDefaultState = function getDefaultState() {
  return {
    previewMsg: {},
    previewNewsMsg: {}
  };
};
var state = getDefaultState();
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_PREVIEW_MSG: function SET_PREVIEW_MSG(state, data) {
    state.previewMsg = data;
  },
  SET_PREVIEW_NEWS_MSG: function SET_PREVIEW_NEWS_MSG(state, data) {
    state.previewNewsMsg = data;
  }
};
var actions = {
  setPreviewMsg: function setPreviewMsg(_ref, previewMsg) {
    var commit = _ref.commit;
    commit('SET_PREVIEW_MSG', previewMsg);
  },
  setPreviewNewsMsg: function setPreviewNewsMsg(_ref2, previewMsg) {
    var commit = _ref2.commit;
    commit('SET_PREVIEW_NEWS_MSG', previewMsg);
  },
  setUserToken: function setUserToken(_ref3, token) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', token);
      setToken(token, '.dsmars.com');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};