import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import Cookies from "js-cookie";
import store from "@/store/index";
var TokenKey = "mars_admin_token";
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token, domains) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function checkPower(path) {
  if (store.state.user.userInfo.auth && store.state.user.userInfo.auth.includes(path)) {
    return true;
  } else {
    return false;
  }
}