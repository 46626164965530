import request from '@/utils/request';
export function pjAdminTree(data) {
  return request({
    url: '/dws/projectAdmin/tree',
    method: 'post',
    data: data
  });
}
export function memberManageDelete(data) {
  return request({
    url: '/dws/member/delete',
    method: 'post',
    data: data
  });
}
export function memberManageAddPerson(data) {
  return request({
    url: '/dws/memberManage/addMember',
    method: 'post',
    data: data
  });
}
export function updateInviteLimit(data) {
  return request({
    url: '/dws/memberManage/updateInviteLimit',
    method: 'post',
    data: data
  });
}
export function userPjMemberDetail(params) {
  return request({
    url: '/dws/memberManage/detail',
    method: 'get',
    params: params
  });
}
export function checkUserPjDetail(params) {
  return request({
    url: '/dws/projectManage/projectDetail',
    method: 'get',
    params: params
  });
}
export function updateUserPj(data) {
  return request({
    url: '/dws/projectManage/updateProject',
    method: 'post',
    data: data
  });
}

// 用户项目创建
export function createUserPj(data) {
  return request({
    url: '/dws/projectManage/createProject',
    method: 'post',
    data: data
  });
}
export function applyPjId(params) {
  return request({
    url: '/dws/project/applyProjectId',
    method: 'get',
    params: params
  });
}
export function getAllCompList(params) {
  return request({
    url: '/dws/activity/allCompList',
    method: 'get',
    params: params
  });
}
export function changePwd(data) {
  return request({
    url: '/ius/user/updateSelfPasswordNotSMS',
    method: 'post',
    data: data
  });
}
export function login(data) {
  return request({
    url: '/ius/user/login',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/ius/user/logout',
    method: 'post'
  });
}

// 获取用户列表
export function getUserList(data) {
  return request({
    url: '/ius/user/list',
    method: 'post',
    data: data
  });
}

// 获取用户详情
export function updateUserInfo(data) {
  return request({
    url: '/ius/user/update',
    method: 'post',
    data: data
  });
}

// 获取用户详情
export function getUserDetail(params) {
  return request({
    url: '/ius/user/detail',
    method: 'get',
    params: params
  });
}

// 获取用户详情
export function getSelfInfo(params) {
  return request({
    url: '/ius/user/selfLoginInfo',
    method: 'get',
    params: params
  });
}

// 获取群组列表
export function getGroupList(data) {
  return request({
    url: '/ius/user/listUserGroup',
    method: 'post',
    data: data
  });
}

// 创建用户群组
export function createGroup(data) {
  return request({
    url: '/ius/user/addUserGroup',
    method: 'post',
    data: data
  });
}

// 删除用户群组 (post请求,但是参数放到请求体中)
export function deleteGroup(params) {
  return request({
    url: '/ius/user/deleteUserGroup',
    method: 'post',
    params: params
  });
}

// 查询用户群组详细信息
export function checkGroupDetail(params) {
  return request({
    url: '/ius/user/detailUserGroup',
    method: 'get',
    params: params
  });
}

// 修改用户群组
export function updateUserGroup(data) {
  return request({
    url: '/ius/user/updateUserGroup',
    method: 'post',
    data: data
  });
}

// 实名认证
export function updateRealNameVerify(data) {
  return request({
    url: '/ius/user/realNameVerifyBackground',
    method: 'post',
    data: data
  });
}

//用户禁言
export function muteUser(data) {
  return request({
    url: '/ius/user/banUser',
    method: 'post',
    data: data
  });
}

//解除用户禁言
export function unMuteUser(data) {
  return request({
    url: '/ius/user/unBanUser',
    method: 'post',
    data: data
  });
}