import request from '@/utils/request';

// 数据字典列表
export function getDictionaryList(params) {
  return request({
    url: '/ba/dic/listByType',
    method: 'get',
    params: params
  });
}

// 新增角色
export function addSysRole(data) {
  return request({
    url: '/ius/sysrole/add',
    method: 'post',
    data: data
  });
}

// 修改角色
export function updateSysRole(data) {
  return request({
    url: '/ius/sysrole/update',
    method: 'post',
    data: data
  });
}

// 获取角色列表
export function getSysRoleList(params) {
  return request({
    url: '/ius/sysrole/list',
    method: 'get',
    params: params
  });
}

// 新增角色资源
export function addSysRoleResource(data) {
  return request({
    url: '/ius/sysResource/add',
    method: 'post',
    data: data
  });
}

// 更新角色资源
export function updateSysRoleResource(data) {
  return request({
    url: '/ius/sysResource/update',
    method: 'post',
    data: data
  });
}

// 获取角色资源
export function getSysRoleResource(params) {
  return request({
    url: '/ius/sysResource/list',
    method: 'get',
    params: params
  });
}

// 新增管理用户
export function addAdminUser(data) {
  return request({
    url: '/ius/adminUser/addAdminUser',
    method: 'post',
    data: data
  });
}

// 获取管理用户
export function getAdminUser(data) {
  return request({
    url: '/ius/adminUser/list',
    method: 'post',
    data: data
  });
}

// 修改管理用户角色
export function updateAdminUserRole(data) {
  return request({
    url: '/ius/adminUser/updateAdminUserRole',
    method: 'post',
    data: data
  });
}

// 获取管理用户权限
export function getUserRoleDetails(params) {
  return request({
    url: '/ius/adminUser/adminUserRoleDetails',
    method: 'get',
    params: params
  });
}

// 设置管理用户禁用启用
export function changeStatus(data) {
  return request({
    url: '/ius/adminUser/updateAdminUserLock',
    method: 'post',
    data: data
  });
}