var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  userName: function userName(state) {
    return state.user.userInfo.userName;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  userAuth: function userAuth(state) {
    return state.user.userAuth;
  },
  previewMsg: function previewMsg(state) {
    return state.activity.previewMsg;
  },
  previewNewsMsg: function previewNewsMsg(state) {
    return state.activity.previewNewsMsg;
  }
};
export default getters;