import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/opt/competition-platform-admin/node_modules/_@babel_runtime-corejs2@7.23.7@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _toConsumableArray from "/opt/competition-platform-admin/node_modules/_@babel_runtime-corejs2@7.23.7@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Axios from "axios";
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from "@/assets/styles/variables.scss";
// import { checkPower } from "@/utils/auth"

// import {getUserRoleDetails} from '@/api/common';

export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  mounted: function mounted() {
    var _this = this;
    var that = this;
    window.sidebar = this;
    setTimeout(function () {
      // console.warn(this.$router.options.routes, this.$store.state.user.userInfo.auth);
      // this.$router.options.routes[3].children[1].hidden = true;
      // getUserRoleDetails({
      //   userId:this.$store.state.user.userInfo.userId
      // }).then(res=>{
      var checkPower = function checkPower(path) {
        var temp = false;
        // let auth = this.$store.state.user.userInfo;
        if (that.$store.state.user.userInfo.auth && that.$store.state.user.userInfo.auth.includes(path)) {
          temp = true;
        }
        return temp;
      };

      // 竞赛模块
      var compCount = 0;
      if (!checkPower("/competition/manage") || !checkPower("/competition/manage/list")) {
        _this.$router.options.routes[3].children[0].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[0].hidden = false;
        compCount = 0;
      }
      // 建模竞赛
      if (!checkPower("/modelComp/manage") || !checkPower("/modelComp/manage/list")) {
        _this.$router.options.routes[3].children[1].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[1].hidden = false;
        compCount = 0;
      }
      // 标签管理
      if (!checkPower("/competition/tag") || !checkPower("/competition/tag/list")) {
        _this.$router.options.routes[3].children[2].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[2].hidden = false;
        compCount = 0;
      }
      // 宣传管理
      if (!checkPower("/banner-manage")) {
        _this.$router.options.routes[3].children[3].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[3].hidden = false;
        compCount = 0;
      }
      // 审核管理
      if (!checkPower("/apply-manage")) {
        _this.$router.options.routes[3].children[4].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[4].hidden = false;
        compCount = 0;
      }

      // 数据参赛列表
      if (!checkPower('/joinInUser')) {
        _this.$router.options.routes[3].children[6].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[6].hidden = false;
        compCount = 0;
      }

      // 建模参赛列表
      if (!checkPower('/modelContest')) {
        _this.$router.options.routes[3].children[7].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[7].hidden = false;
        compCount = 0;
      }

      // 作品列表
      if (!checkPower('/worksList')) {
        _this.$router.options.routes[3].children[8].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[8].hidden = false;
        compCount = 0;
      }

      // 任务列表
      if (!checkPower('/taskList')) {
        _this.$router.options.routes[3].children[9].hidden = true;
        compCount++;
      } else {
        _this.$router.options.routes[3].children[9].hidden = false;
        compCount = 0;
      }
      if (compCount == 9) {
        _this.$router.options.routes[3].hidden = true;
      } else {
        _this.$router.options.routes[3].hidden = false;
      }
      // 数据集管理模块
      var datasetCount = 0;
      if (!checkPower("/dataset/datasetManager") || !checkPower("/dataset/datasetManager/list")) {
        _this.$router.options.routes[4].children[0].hidden = true;
        datasetCount++;
      } else {
        _this.$router.options.routes[4].children[0].hidden = false;
        datasetCount = 0;
      }
      if (!checkPower("/userDataset/datasetManager") || !checkPower("/userDataset/datasetManager/list")) {
        _this.$router.options.routes[4].children[1].hidden = true;
        datasetCount++;
      } else {
        _this.$router.options.routes[4].children[1].hidden = false;
        datasetCount = 0;
      }
      if (!checkPower("/dataset/tagManager") || !checkPower("/dataset/tagManager/list")) {
        _this.$router.options.routes[4].children[2].hidden = true;
        datasetCount++;
      } else {
        _this.$router.options.routes[4].children[2].hidden = false;
        datasetCount = 0;
      }
      _this.$router.options.routes[4].hidden = datasetCount === 3;

      // 模板管理模块
      _this.$router.options.routes[5].hidden = !checkPower("/template") || !checkPower("/template/list");

      // 用户管理模块
      var usermanageCount = 0;
      if (!checkPower("/usermanage") || !checkPower("/usermanage/list")) {
        _this.$router.options.routes[6].children[0].hidden = true;
        usermanageCount++;
      } else {
        _this.$router.options.routes[6].children[0].hidden = false;
        usermanageCount = 0;
      }
      if (!checkPower("/usermanage") || !checkPower("/usermanage/group")) {
        _this.$router.options.routes[6].children[1].hidden = true;
        usermanageCount++;
      } else {
        _this.$router.options.routes[6].children[1].hidden = false;
        usermanageCount = 0;
      }
      _this.$router.options.routes[6].hidden = usermanageCount === 2;

      // 兑换码 (一级菜单)
      _this.$router.options.routes[9].hidden = !checkPower("/exchangeCode") || !checkPower("/exchangeCode/list");

      // 计算资源管理
      var compResourceCount = 0;
      // if (!checkPower("/computingResource") || !checkPower("/computingResource/list")) {
      //   this.$router.options.routes[10].children[0].hidden = true;
      //   compResourceCount++;
      // } else {
      //   this.$router.options.routes[10].children[0].hidden = false;
      //   compResourceCount = 0;
      // }
      if (!checkPower("/computingResource") || !checkPower("/computingResource/set")) {
        _this.$router.options.routes[10].children[0].hidden = true;
        compResourceCount++;
      } else {
        _this.$router.options.routes[10].children[0].hidden = false;
        compResourceCount = 0;
      }
      _this.$router.options.routes[10].hidden = compResourceCount === 1;

      // 项目管理
      var pjCount = 0;
      if (!checkPower("/projectmanage") || !checkPower("/projectmanage/public")) {
        _this.$router.options.routes[11].children[0].hidden = true;
        pjCount++;
      } else {
        _this.$router.options.routes[11].children[0].hidden = false;
        pjCount = 0;
      }
      if (!checkPower("/projectmanage") || !checkPower("/projectmanage/userProjectList")) {
        _this.$router.options.routes[11].children[1].hidden = true;
        pjCount++;
      } else {
        _this.$router.options.routes[11].children[1].hidden = false;
        pjCount = 0;
      }
      _this.$router.options.routes[11].hidden = pjCount === 2;

      // 社区模块
      var commCount = 0;
      if (!checkPower("/community/post-review") || !checkPower("/community/post-review/list")) {
        _this.$router.options.routes[7].children[0].hidden = true;
        commCount++;
      } else {
        _this.$router.options.routes[7].children[0].hidden = false;
        commCount = 0;
      }
      if (!checkPower("/community/post-manage") || !checkPower("/community/post-manage/list")) {
        _this.$router.options.routes[7].children[1].hidden = true;
        commCount++;
      } else {
        _this.$router.options.routes[7].children[1].hidden = false;
        commCount = 0;
      }
      if (!checkPower("/community/tag-manage") || !checkPower("/community/tag-manage/list")) {
        _this.$router.options.routes[7].children[2].hidden = true;
        commCount++;
      } else {
        _this.$router.options.routes[7].children[2].hidden = false;
        commCount = 0;
      }
      if (!checkPower("/community/ogc") || !checkPower("/community/ogc/list")) {
        _this.$router.options.routes[7].children[3].hidden = true;
        commCount++;
      } else {
        _this.$router.options.routes[7].children[3].hidden = false;
        commCount = 0;
      }
      if (!checkPower("/community/integral-manage") || !checkPower("/community/integral-manage/list")) {
        _this.$router.options.routes[7].children[4].hidden = true;
        commCount++;
      } else {
        _this.$router.options.routes[7].children[4].hidden = false;
        commCount = 0;
      }
      _this.$router.options.routes[7].hidden = commCount === 5;

      // 系统模块
      var sysCount = 0;
      if (!checkPower("/adminconf/userconf") || !checkPower("/adminconf/userconf/list")) {
        _this.$router.options.routes[8].children[0].hidden = true;
        sysCount++;
      } else {
        _this.$router.options.routes[8].children[0].hidden = false;
        sysCount = 0;
      }
      if (!checkPower("/adminconf/roleconf") || !checkPower("/adminconf/roleconf/list")) {
        _this.$router.options.routes[8].children[1].hidden = true;
        sysCount++;
      } else {
        _this.$router.options.routes[8].children[1].hidden = false;
        sysCount = 0;
      }
      _this.$router.options.routes[8].hidden = sysCount === 2;

      // 活动管理 (二级菜单)
      var actCount = 0;
      if (!checkPower("/activity/list")) {
        _this.$router.options.routes[12].children[0].hidden = true;
        actCount++;
      } else {
        _this.$router.options.routes[12].children[0].hidden = false;
        actCount = 0;
      }
      if (!checkPower("/activePersonnel/list")) {
        _this.$router.options.routes[12].children[1].hidden = true;
        actCount++;
      } else {
        _this.$router.options.routes[12].children[1].hidden = false;
        actCount = 0;
      }
      _this.$router.options.routes[12].hidden = actCount === 2;
      // this.$router.options.routes[12].hidden = !checkPower("/activity/list");

      // 资讯动态 (一级菜单)
      _this.$router.options.routes[14].hidden = !checkPower("/news/list");

      // AI工具管理 (一级菜单)
      _this.$router.options.routes[15].hidden = !checkPower("/aiTools/list");

      // 内容管理
      var contentCount = 0;
      if (!checkPower("/content/recommend")) {
        _this.$router.options.routes[16].children[0].hidden = true;
        contentCount++;
      } else {
        _this.$router.options.routes[16].children[0].hidden = false;
        contentCount = 0;
      }
      _this.$router.options.routes[16].hidden = contentCount === 1;

      // 课程管理 (一级菜单)
      _this.$router.options.routes[18].hidden = !checkPower("/courseManage/list");
      _this.routes = _toConsumableArray(_this.$router.options.routes);
    }, 100);
    // })
    // console.log(this.$router.options.routes);
  },
  data: function data() {
    return {
      routes: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["sidebar"])), {}, {
    // routes() {
    //   // console.log("router 触发");
    //   return this.$router.options.routes
    // },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};