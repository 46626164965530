import "core-js/modules/es6.number.constructor";
import { FILTER_OBJ } from "../constant";
function addZero(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}
function timeFilter(timestamp, format) {
  timestamp = Number(timestamp);
  var dateTime = new Date(timestamp);
  var year = dateTime.getFullYear();
  var month = addZero(dateTime.getMonth() + 1);
  var day = addZero(dateTime.getDate());
  var hour = addZero(dateTime.getHours());
  var minute = addZero(dateTime.getMinutes());
  var second = addZero(dateTime.getSeconds());
  var formatMap = {
    'yyyy': 0,
    'yyyy-MM': 1,
    'yyyy-MM-dd': 2,
    'HH:mm': 3,
    'MM-dd HH:mm': 4,
    'yyyy-MM-dd HH:mm': 5,
    'yyyy-MM-dd HH:mm:ss': 6,
    'yyyy/MM/dd': 7,
    'MM-dd': 8
  };
  var formatType = formatMap[format];
  var result = '';
  switch (formatType) {
    case 0:
      result = "".concat(year);
      break;
    case 1:
      result = "".concat(year, "-").concat(month);
      break;
    case 2:
      result = "".concat(year, "-").concat(month, "-").concat(day);
      break;
    case 3:
      result = "".concat(hour, ":").concat(minute);
      break;
    case 4:
      result = "".concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute);
      break;
    case 5:
      result = "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute);
      break;
    case 6:
      result = "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
      break;
    case 7:
      result = "".concat(year, "\u5E74").concat(month, "\u6708").concat(day, "\u65E5");
      break;
    case 8:
      result = "".concat(month, "-").concat(day);
      break;
    default:
      result = "".concat(year, "-").concat(month, "-").concat(day);
      break;
  }
  return result;
}
function weekFilter(val) {
  if (!val) {
    return "";
  }
  var date = new Date(val);
  var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  return weekday[date.getDay()];
}
var globalFilter = function globalFilter(value, filter, format) {
  if (value === null || value === undefined) {
    return filter ? '-' : value;
  }
  if (filter) {
    if (format) {
      return eval("".concat(filter, "(").concat(JSON.stringify(value), ",").concat(JSON.stringify(format), ")"));
    } else {
      return FILTER_OBJ[filter] && FILTER_OBJ[filter] ? FILTER_OBJ[filter][value] : value;
    }
  } else {
    return value;
  }
};
export { timeFilter, weekFilter, globalFilter };