import { login as _login, logout as _logout } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import { getUserRoleDetails } from "@/api/common";
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    userInfo: {}
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
    // sessionStorage.removeItem("auth");
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERINFO: function SET_USERINFO(state, data) {
    state.userInfo = data;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var loginNameOrMobile = userInfo.loginNameOrMobile,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        loginNameOrMobile: loginNameOrMobile.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        getUserRoleDetails({
          userId: data.userId
        }).then(function (res) {
          data.auth = res ? res.data.join(",") : [];
          commit('SET_USERINFO', data);
          resolve(data);
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setUserToken: function setUserToken(_ref2, token) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      commit("SET_TOKEN", token);
      setToken(token, ".dsmars.com");
      resolve();
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout().then(function () {
        removeToken(); // must remove  token  first
        resetRouter();
        commit("RESET_STATE");
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};